import React from 'react';
import { navigate } from 'gatsby';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import AuthWrapper from '../../components/layout/AuthWrapper';
import BulkImport from '../../components/organisms/bulk-import/BulkImport';
import { BulkImportBackLinkNavigation } from '../../components/organisms/bulk-import/bulkImport.style';
import Button from '../../components/atoms/button/Button';
import { IconChevronLeft } from '../../components/icons';
import ImportLearnersFeatureWrapperProps from '../../components/layout/ImportLearnersFeatureWrapper';

const BulkImportLearnersPage = () => {
  return (
    <AuthWrapper>
      <ImportLearnersFeatureWrapperProps
        checkForLearnersDataHook={false}
        redirectTo="/list-learners"
      >
        <Head title="BulkImportLearnersPage" />
        <Layout
          breadcrumbs={[
            { link: '/', label: 'Home' },
            { link: '/list-learners', label: 'Learners' },
            { link: '/bulk-import-learners', label: 'Bulk Import Learners' },
          ]}
        >
          <BulkImportBackLinkNavigation>
            <Button
              iconLeft={<IconChevronLeft responsive />}
              onClick={() => {
                navigate('/list-learners');
              }}
              variant="naked"
            >
              Back to Learner record
            </Button>
          </BulkImportBackLinkNavigation>
          <BulkImport />
        </Layout>
      </ImportLearnersFeatureWrapperProps>
    </AuthWrapper>
  );
};

export default BulkImportLearnersPage;
