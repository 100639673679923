import { navigate } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import { GetCsvData } from '../../context-providers/csv/csv-hooks';
import { ImportLearnerFeatureStatus } from '../../context-providers/enums/global-enums';
import { ILearnerImport } from '../../context-providers/learner/learner-reducer';
import { fetchAPI } from '../../utils/fetch-api';

interface IImportLearnersFeatureWrapperProps {
  children?: React.ReactNode;
  checkForLearnersDataHook: boolean;
  redirectTo?: string;
}

const ImportLearnersFeatureWrapperProps = ({
  children,
  checkForLearnersDataHook,
  redirectTo = '/',
}: IImportLearnersFeatureWrapperProps) => {
  const [canLoadPage, setCanLoadPage] = useState(false);
  const data = GetCsvData();

  const loadFeatures = useCallback(async () => {
    const redirect = () => {
      navigate(redirectTo);
    };
    const response = await fetchAPI<ILearnerImport>({
      path: 'feature',
      method: 'GET',
    });

    if (
      !response.success ||
      response.body.importLearners !== ImportLearnerFeatureStatus.Enabled
    ) {
      return redirect();
    }

    if (checkForLearnersDataHook) {
      if (!data?.length) {
        return redirect();
      }
    }
    setCanLoadPage(true);
  }, [redirectTo, checkForLearnersDataHook, data?.length]);

  useEffect(() => {
    loadFeatures();
  }, [loadFeatures]);

  return <>{canLoadPage && children}</>;
};

export default ImportLearnersFeatureWrapperProps;
