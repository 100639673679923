import styled from 'styled-components';
import { color } from '../../config';

export interface ILineBreakProps {
  padding?: string;
  margin?: string;
}

/**
 * @param padding default 0 or "top, right, bottom, left"
 * @param margin default 0 or "top, right, bottom, left"
 */

export const LineBreak = styled.div<ILineBreakProps>`
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  border-bottom: 1px solid ${color.grey.lightest};
`;
