import styled from 'styled-components';

export type StyleJustifyContent = 'center' | 'space-between' | 'end';
export type AlignItems = 'center';
export type FlexDirection = 'column' | 'row';
export interface IFlexProps {
  justifyContent?: StyleJustifyContent;
  alignItems?: AlignItems;
  direction?: FlexDirection;
  gap?: number;
  padding?: string;
}

/**
 * @param padding default 0 or "top, right, bottom, left"
 * @param margin default 0 or "top, right, bottom, left"
 * @param textAlign default "auto"
 * @param borderTop default none or boolean
 * @param borderBottom default none or boolean
 */

export const Flex = styled.div<IFlexProps>`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'row')};
  ${(props) =>
    props.justifyContent && `justify-content:${props.justifyContent};`};
  ${(props) => props.alignItems && `align-items:${props.alignItems};`};
  ${(props) => props.gap && `gap:${props.gap}px;`};
  ${(props) => props.padding && `padding:${props.padding};`};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
`;

export interface IFlexGridProps {
  row?: number;
  column?: number;
}

export const FlexGrid = styled.div<IFlexGridProps>`
  display: flex;
  height: 100%;
  row-gap: ${(props) => `${props.row ? props.row : '0.5'}em;`}
  column-gap: ${(props) => `${props.column ? props.column : '0.5'}em;`}
  flex-wrap: wrap;
`;
