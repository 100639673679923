import { IFormTemplate } from '../context-providers/forms/forms-reducer';

export const ParseJsonHelper = (jsondata: string) => {
  return JSON.parse(jsondata);
};

export const SplitLargeStringIntoIndividualArraysHelper = (
  sourceString: string,
  length: number,
): string[] => {
  return sourceString.match(new RegExp(`.{1,${length}}`, 'g')) ?? [];
};

export const TruncateStringHelper = (
  sourceString: string,
  num: number,
): string => {
  if (sourceString.length <= num) {
    return sourceString;
  }
  return `${sourceString.slice(0, num)}...`;
};

export const FormDataTrimmerHelper = (formTemplate: IFormTemplate) => {
  const trimmedFormData = formTemplate.formData.map((item) => {
    if (item.itemType === 'text' || item.itemType === 'prepop') {
      let newprops = { ...item.props, value: item.props.value?.trim() };

      return { ...item, props: newprops };
    }

    return item;
  });
  return { ...formTemplate, formData: trimmedFormData };
};
