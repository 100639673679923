import Papa, { ParseError } from 'papaparse';

export interface ResultStructure {
  data: string[][];
  errors: ParseError[];
  meta: any;
}

export const CSVReader = async (csvData: string): Promise<ResultStructure> => {
  const parsed = Papa.parse(csvData);
  const unparsed = Papa.unparse(parsed.data, { escapeFormulae: true });
  return Papa.parse(unparsed);
};
