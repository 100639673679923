import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { boxShadow, color } from '../../config';

type TOuterLayerDeleteButton = HTMLAttributes<HTMLButtonElement>;

export const DragDropZone = styled.div`
  z-index: 1;
  width: 100%;
  padding: 20px;
  margin: 0;
  cursor: pointer;
`;

export const DragDropZoneBorderLine = styled.div<{
  borderLine: boolean | null;
}>`
  border-radius: 12px;
  ${({ borderLine }) => {
    if (borderLine) {
      return `
        border: 2px dashed ${color.error.background};
      `;
    }
    return `
        border: 2px dashed ${color.primary.base};
      `;
  }}
`;

export const FileName = styled.div`
  margin-left: 10px;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
`;

export const OuterLayerDeleteButton = styled.button<TOuterLayerDeleteButton>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  z-index: 999;
  display: flex;
  margin-right: 26px;
  border-radius: 6px;

  &:focus {
    box-shadow: ${boxShadow.primary};
  }
`;

export const OuterLayer = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: ${color.primary.lighter};
`;
