import React, { useState } from 'react';
import { navigate } from 'gatsby';
import DragDropCsv, {
  DragDropError,
} from '../../atoms/drag-drop-csv/DragDropCsv';
import { Box, WidthBox } from '../../atoms/custom-elements/Box.style';
import { Powergraph } from '../../atoms/custom-elements/Powergraph.style';
import GridFlex from '../../atoms/grid-flex/GridFlex';
import Stack from '../../atoms/stack/Stack';
import {
  ListNumbersCircle,
  Column,
  FullWidthContainer,
  Item,
  Row,
} from '../../layout/Layout.style';
import {
  ClearHeaders,
  ClearLearners,
  SetCsvData,
} from '../../../context-providers/csv/csv-hooks';
import Button from '../../atoms/button/Button';
import { IconArrowRight } from '../../icons';
import { CSVReader, ResultStructure } from '../../../utils/csv-reader';
import StaticFileUtil from '../../../utils/static-file-util';
import Checkbox from '../../atoms/form-fields/checkbox/Checkbox';
import { LineBreak } from '../../atoms/custom-elements/LineBreak';
import Message from '../../atoms/message/Message';

const BulkImport = () => {
  const data = StaticFileUtil.GetCsvFiles();
  const setNewCsvData = SetCsvData();
  const UseClearLearners = ClearLearners();
  const UseClearHeaders = ClearHeaders();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [toggleheaders, settoggleheaders] = useState<boolean>(false);

  const [csvResults, setcsvResults] = useState<ResultStructure | null>(null);

  const onSubmit = () => {
    if (!csvResults?.data) {
      throw new Error('cannot submit empty csv');
    }

    if (toggleheaders) {
      csvResults.data.shift();
    }

    setNewCsvData(csvResults.data);
    return navigate('/bulk-import-learners/select-headers');
  };

  const handleFileError = (error: DragDropError) => {
    setcsvResults(null);

    switch (error) {
      case DragDropError.NO_FILE_SELECTED:
        setErrorMessage('Please select a file');
        break;
      case DragDropError.FILE_TYPE:
        setErrorMessage('Please select a CSV file');
        break;
      case DragDropError.EMPTY_FILE:
        setErrorMessage('The file is empty');
        break;
      case DragDropError.MAX_NUMBER_OF_FILE:
        setErrorMessage('Please select only one file');
        break;
      default:
        setErrorMessage('Unknown error');
        break;
    }
  };

  const validateFile = (
    resultStructure: ResultStructure | null,
    toggleHeaders: boolean,
  ) => {
    if (!resultStructure?.data) {
      setErrorMessage('Please select a file');
      return;
    }

    if (toggleHeaders) {
      if (resultStructure.data.length < 2) {
        setErrorMessage('Please select a file with at least two rows');
        return;
      }
    } else if (resultStructure.data.length < 1) {
      setErrorMessage('Please select a file with at least one row');
      return;
    }

    setErrorMessage('');
  };

  const handleFileChange = async (fileAsString: string | null) => {
    setErrorMessage('');

    let resultStructure: ResultStructure | null = null;

    if (!fileAsString) {
      return;
    }

    try {
      resultStructure = await CSVReader(fileAsString);
      setcsvResults(resultStructure);
      UseClearLearners();
      UseClearHeaders();
      validateFile(resultStructure, toggleheaders);
    } catch {
      setErrorMessage('Error reading file');
    }
  };

  const onCheckboxChange = () => {
    settoggleheaders(!toggleheaders);
    validateFile(csvResults, !toggleheaders);
  };

  return (
    <Stack stackMultiplier={2.5}>
      <GridFlex justify="center">
        <WidthBox width="800px">
          <Box margin="32px 0">
            <h2>Import multiple learners</h2>
            <Box margin="32px 0">
              <FullWidthContainer>
                <Box margin="0 60px">
                  <h1>Upload your import file</h1>
                  <Powergraph margin="12px 0">
                    Follow the 3 simple steps below to import multiple learner
                    records in one go.
                  </Powergraph>
                  <Box margin="32px 0">
                    <Row>
                      <WidthBox width="35px">
                        <Column>
                          <ListNumbersCircle>1</ListNumbersCircle>
                        </Column>
                      </WidthBox>
                      <Column>
                        <Item>
                          <Powergraph>
                            <a href={data[0].publicURL}>
                              Download our spreadsheet template
                            </a>{' '}
                            and use it to add in all your learner records.
                          </Powergraph>
                        </Item>
                      </Column>
                    </Row>

                    <Box margin="24px 0">
                      <Row>
                        <WidthBox width="35px">
                          <Column>
                            <ListNumbersCircle>2</ListNumbersCircle>
                          </Column>
                        </WidthBox>
                        <Column>
                          <Item>
                            <Powergraph>
                              You must include the first name, last name and
                              email address of each learner. And, at least one
                              row must be completed.
                            </Powergraph>
                          </Item>
                        </Column>
                      </Row>
                    </Box>

                    <Row>
                      <WidthBox width="35px">
                        <Column>
                          <ListNumbersCircle>3</ListNumbersCircle>
                        </Column>
                      </WidthBox>
                      <Column>
                        <Item>
                          <Powergraph>
                            Save your completed spreadsheet as a .CSV file and
                            upload it below:
                          </Powergraph>
                          <Box margin="24px 0">
                            <DragDropCsv
                              hasError={!!errorMessage}
                              onError={handleFileError}
                              onFileChange={handleFileChange}
                            />

                            <Message
                              id="DragDropCsv-error"
                              error
                              text={errorMessage}
                            />

                            <Box margin="16px 0">
                              <Checkbox
                                inline
                                aria-label="Tick here if the first row in the CSV file contains column headers"
                                checked={toggleheaders}
                                readOnly={false}
                                id="contains-column-headers"
                                dataQa="import-chk-headers"
                                label="Tick here if the first row in the CSV file contains column headers"
                                onChange={onCheckboxChange}
                              />
                            </Box>
                          </Box>
                        </Item>
                      </Column>
                    </Row>
                  </Box>
                </Box>
                <Box margin="32px 104px">
                  <Box margin="0 0 32px">
                    <LineBreak />
                  </Box>
                  <br />
                  <GridFlex justify="right">
                    <Button
                      onClick={() => navigate('/list-learners')}
                      variant="subtle"
                    >
                      Cancel
                    </Button>
                    <Button
                      iconRight={<IconArrowRight responsive />}
                      disabled={!!errorMessage || !csvResults}
                      dataQa="import-btn-continue"
                      onClick={onSubmit}
                    >
                      Continue
                    </Button>
                  </GridFlex>
                </Box>
              </FullWidthContainer>
            </Box>
          </Box>
        </WidthBox>
      </GridFlex>
    </Stack>
  );
};
export default BulkImport;
