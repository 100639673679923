import { useContext } from 'react';
import { ImportHeader } from '../../components/organisms/import-learners-table/import-learner-constants';
import { ImportLearnerValidation } from '../../components/organisms/import-learners-table/import-learner-validation';
import { CSVparserContext } from './csv-context';

export const GetCsvData = () => {
  const { state } = useContext(CSVparserContext);
  return state.data;
};

export const GetSelectedHeaders = () => {
  const { state } = useContext(CSVparserContext);
  return state.headers;
};

export const SetSelectedHeaders = () => {
  const { dispatch } = useContext(CSVparserContext);

  return (headers: ImportHeader[]) =>
    dispatch({ type: 'SET_HEADERS', headers });
};

export const GetImportLearners = () => {
  const { state } = useContext(CSVparserContext);
  return state.learners;
};

export const SetImportLearners = () => {
  const { dispatch } = useContext(CSVparserContext);

  return (learners: ImportLearnerValidation[]) =>
    dispatch({ type: 'SET_IMPORT_LEARNERS', learners });
};

export const SetCsvData = () => {
  const { dispatch } = useContext(CSVparserContext);
  return (data: string[][]) => dispatch({ type: 'SET_CSV', csvdata: data });
};

export const ClearCsvData = () => {
  const { dispatch } = useContext(CSVparserContext);
  return () => dispatch({ type: 'CLEAR_CSV' });
};

export const ClearHeaders = () => {
  const { dispatch } = useContext(CSVparserContext);
  return () => dispatch({ type: 'CLEAR_HEADERS' });
};

export const ClearLearners = () => {
  const { dispatch } = useContext(CSVparserContext);
  return () => dispatch({ type: 'CLEAR_LEARNERS' });
};

export const ClearAllData = () => {
  const { dispatch } = useContext(CSVparserContext);
  return () => dispatch({ type: 'CLEAR_ALL' });
};
