import { graphql, useStaticQuery } from 'gatsby';

export interface IStaticFile {
  publicURL: string;
  name: string;
}

const StaticFileUtil = {
  GetCsvFiles: (): IStaticFile[] => {
    const data = useStaticQuery(graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "csv" } }) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `);

    return data.allFile.edges.map((file) => file.node);
  },
};

export default StaticFileUtil;
