import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { TruncateStringHelper } from '../../../utils/helper-functions';
import { color } from '../../config';
import { IconCheck, IconDelete, IconUpload } from '../../icons';
import { Column, Row } from '../../layout/Layout.style';
import { Flex } from '../custom-elements/Flex.style';
import {
  DragDropZone,
  DragDropZoneBorderLine,
  FileName,
  OuterLayer,
  OuterLayerDeleteButton,
} from './DragDropCsv.style';

interface DragDropCsvProps {
  onError: (errorCode: DragDropError) => void;
  onFileChange: (fileAsString: string | null) => void;
  hasError: boolean;
}

export enum DragDropError {
  UNKNOWN = 0,
  NO_FILE_SELECTED = 1,
  FILE_TYPE = 2,
  EMPTY_FILE = 3,
  MAX_NUMBER_OF_FILE = 4,
}

const DragDropCsv = ({ onError, onFileChange, hasError }: DragDropCsvProps) => {
  const defaultFileName = 'Drag and drop, or choose your file';
  const [fileName, setfileName] = useState(defaultFileName);
  const [toggle, settoggle] = useState<boolean>(false);

  const emitError = (errorCode: DragDropError) => {
    onError(errorCode);
  };

  const onDrop2 = (files: File[]) => {
    if (!files) {
      return emitError(DragDropError.NO_FILE_SELECTED);
    }

    if (files.length !== 1) {
      return emitError(DragDropError.MAX_NUMBER_OF_FILE);
    }

    const file: File = files[0];

    if (!file?.name?.includes('.csv')) {
      return emitError(DragDropError.FILE_TYPE);
    }

    const reader = new FileReader();

    reader.onload = async () => {
      const binaryStr = reader.result;
      const fileAsString =
        typeof binaryStr === 'string' ? binaryStr.trim() : '';

      if (fileAsString.length < 1) {
        return emitError(DragDropError.EMPTY_FILE);
      }

      setfileName(file.name);
      settoggle(true);
      onFileChange(fileAsString);
    };

    reader.readAsText(file);
  };

  const setToDefault = () => {
    setfileName(defaultFileName);
    settoggle(false);
    onFileChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDrop2 });
  return (
    <>
      <DragDropZoneBorderLine borderLine={hasError}>
        <Flex alignItems="center">
          <Column>
            <DragDropZone {...getRootProps()}>
              <Row>
                {toggle ? (
                  <OuterLayer>
                    <IconCheck width={25} fill={color.primary.base} />
                  </OuterLayer>
                ) : (
                  <OuterLayer>
                    <IconUpload width={25} fill={color.primary.base} />
                  </OuterLayer>
                )}
                <input
                  aria-label="drag and drop file or select one"
                  disabled={toggle}
                  type="file"
                  accept=".csv"
                  data-qa="csv-btn-upload"
                  {...getInputProps()}
                />

                <FileName>{TruncateStringHelper(fileName, 49)}</FileName>
              </Row>
            </DragDropZone>
          </Column>
          {toggle && (
            <OuterLayerDeleteButton
              aria-label="delete selected csv file"
              data-qa="csv-btn-delete"
              onKeyDown={(e) => e.key === 'Enter' && setToDefault()}
              tabIndex={0}
              onClick={() => setToDefault()}
            >
              <IconDelete width={25} />
            </OuterLayerDeleteButton>
          )}
        </Flex>
      </DragDropZoneBorderLine>
    </>
  );
};

export default DragDropCsv;
